import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { showLoader$ } from '../../store'

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LoaderComponent {
  protected readonly showLoader$ = showLoader$;
}
