<div class="-oneX -oneX-modal__container" data-session-timeout id="oneX-modal-sess-timeout" style="display: none">
  <div aria-labelledby="dialogAction" class="-oneX-modal-content" role="dialog">
    <div aria-level="2" class="-oneX-modal-heading" id="dialogAction" role="heading" tabindex="-1">
      Are you still there?
    </div>
    <div class="-oneX-modal-body">
      Your session will expire in
      <span aria-atomic="true" aria-live=" " class="-oneX-font--bold" id="modal_timer" role="timer">
				{{ sessionPopUpTimeRemaining }} seconds
			</span>
      Would you like to extend the session?
    </div>
    <div class="-oneX-modal-footer">
      <div class="-oneX-row">
        <div class="-oneX-modal-primary__div -oneX-col">
          <button (click)="renewSession()" class="-oneX-btn-primary" id="oneX-modal-primary-action-2">Extend</button>
        </div>
        <div class="-oneX-modal-secondary__div -oneX-col">
          <button (click)="terminateSession()" class="-oneX-btn-secondary" id="oneX-modal-secondary-action-2">
            Log Out
          </button>
        </div>
      </div>
    </div>
    <button (click)="renewSession()" aria-label="Close Session Time Out" class="-oneX-close-X-button">
      <span class="-oneX-icon--close"> </span>
    </button>
  </div>
</div>
