import { Routes } from '@angular/router'
import { policyViewGuard } from './guards/policy-view.guard'
import { policyViewResolver } from './resolvers/policy-view.resolver'

export const routes: Routes = [
  {
    path: 'policy-view/home',
    loadComponent: () => import('./pages/policy-view/policy-view.component').then(m => m.PolicyViewComponent),
    canActivate: [policyViewGuard],
    resolve: { policyView: policyViewResolver }
  },
  {
    path: 'error',
    loadComponent: () => import('./pages/error/error.component').then(m => m.ErrorComponent)
  },
  {
    path: '',
    redirectTo: 'policy-view/home',
    pathMatch: 'full'
  },
  {
    path: 'idCards',
    loadComponent: () => import('./components/id-card-loader/app-id-card-loader.component').then(m => m.IdCardLoaderComponent)
  },
  {
    path: 'idCardError',
    loadComponent: () => import('./pages/id-card-error/id-card-error.component').then(m => m.IdCardErrorComponent)
  }
]
