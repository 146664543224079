import { ErrorHandler, Injectable } from '@angular/core'
import { error$, logger$ } from '../store'
import { Router } from '@angular/router'

@Injectable()
export class PolicyViewErrorHandler extends ErrorHandler {
  constructor(private router: Router) {
    super()
  }

  override handleError(error: any) {
    super.handleError(error)
    error$.set('unknownError');
    if(error.stack){
      const stackLines = error.stack?.split('\n').slice(0, 2);
      const chunkMatch = stackLines[1].match(/chunk-([^/]+)\.js:(\d+):(\d+)/);
      const [_, chunkFile, line, column] = chunkMatch || [];
      logger$.set(`${error.mapperName}: ${stackLines[0]} chunk-${chunkFile}: ${line}:${column}` || error);
    }else {
      logger$.set(`${error}-Error Outside Mappers`)
    }

    this.router.navigateByUrl('error').catch(console.error);
  }
}
