import { Component } from '@angular/core'
import { ActivatedRoute, RouterOutlet } from '@angular/router'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { LoaderComponent } from './components/loader/loader.component'
import { AppEffects } from './store'
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LoaderComponent, SessionTimeoutComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  showHeaderFooterLinks = true

  constructor(private activatedRoute: ActivatedRoute, private appEffects: AppEffects) {
    activatedRoute.queryParams.pipe(takeUntilDestroyed()).subscribe(async params => {
      this.showHeaderFooterLinks = !(params['native'] === 'true')
    })
  }
}
