import { ApplicationConfig, ErrorHandler } from '@angular/core'
import { provideRouter, withComponentInputBinding } from '@angular/router'

import { routes } from './app.routes'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { httpInterceptor } from './interceptors/http.interceptor'
import { PolicyViewErrorHandler } from './handlers/error.handler'

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: ErrorHandler, useClass: PolicyViewErrorHandler },
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([httpInterceptor]))
  ]
}
