import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import createActivityDetector from 'activity-detector'
import { ONEX } from '../../constants/app.constants'
import { logout } from '../../util/app.util'
import { refreshOkta$ } from '../../store'


@Component({
  selector: 'app-session-timeout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './session-timeout.component.html',
  styleUrl: './session-timeout.component.scss'
})
export class SessionTimeoutComponent {
  // Timeout and countdown values
  readonly millisecondsInSeconds: number = 1000
  readonly idleTimeoutTime: number = this.millisecondsInSeconds * 60 * 13.5
  readonly modalOpenTimeoutCountdownSeconds: number = 90

  // Component state values
  sessionPopUpTimeRemaining: number
  sessionPopUpInterval: any
  activityDetector: any

  ngOnInit() {
    // Start timers
    this.declareSessionInactivityTimer()
    this.initSessionInactivityTimer()
  }

  declareSessionInactivityTimer() {
    const idleUserEventList = [
      'click',
      'mousemove',
      'keydown',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'focus'
    ]

    this.activityDetector = createActivityDetector({
      timeToIdle: this.idleTimeoutTime,
      // Events that will be monitored to determine if the user is idle
      activityEvents: idleUserEventList,
      // Ignore these events to prevent from switching state from idle to active
      // We want to extend the idle time in the browser via the countdown in the modal
      ignoredEventsWhenIdle: idleUserEventList,
      // Prevent default behavior of tab switching causing timeout
      inactivityEvents: [],
      // Don't auto init so that we can re-init when user clicks renew session
      autoInit: false
    })
  }

  initSessionInactivityTimer() {
    // Track time remaining client side and monitor for inactivity

    this.activityDetector.init()
    this.activityDetector.on('idle', () => {
      this.activityDetector.stop()
      this.openSessionModal()
    })
  }

  openSessionModal() {
    // Start modal countdown
    this.sessionPopUpTimeRemaining = this.modalOpenTimeoutCountdownSeconds
    this.sessionPopUpInterval = setInterval(async () => {
      if (this.sessionPopUpTimeRemaining > 0) {
        this.sessionPopUpTimeRemaining--
      } else {
        clearInterval(this.sessionPopUpInterval)
        await this.terminateSession()
      }
    }, this.millisecondsInSeconds)

    // Show the modal
    ONEX.Modal.showModal(this, 'oneX-modal-sess-timeout')
  }

  async renewSession() {
    refreshOkta$.set('renewSession')
    ONEX.Modal.hideSessionModal()
    clearInterval(this.sessionPopUpInterval)
    this.initSessionInactivityTimer()
  }

  async terminateSession() {
    ONEX.Modal.hideSessionModal()
    logout()
  }
}
