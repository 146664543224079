import { inject } from '@angular/core'
import { ResolveFn } from '@angular/router'
import { ApiService } from '../services/api.service'
import { env$, logger$ } from '../store'

export const policyViewResolver: ResolveFn<any> = (route, state) => {
  const tdmToken = route.queryParamMap.get('token')
  const env = route.queryParamMap.get('testEnv')
  if (env) env$.set(env);
  if (!tdmToken) {
    const tdmErrorMessage = 'Retrive TDM token Failed'
    logger$.set(tdmErrorMessage)
    throw ('missingTDM')
  }
  return inject(ApiService).policyRetrieve(tdmToken)
}
