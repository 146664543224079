import { HttpErrorResponse, HttpHeaders, HttpInterceptorFn, HttpRequest } from '@angular/common/http'
import { environment } from '@pv/constant'
import { catchError, finalize } from 'rxjs'
import { authToken$, env$, logger$, showLoader$ } from '../store'
import { flowId } from '../constants/app.constants'
import { logout } from '../util/app.util'

const addExtraHeaders = (headers: HttpHeaders): HttpHeaders => {
  const authToken = authToken$()
  const env = env$()
  let httpHeaders = undefined
  httpHeaders = headers.set('x_sf_flow_id', flowId)

  if (authToken.type === 'okta') {
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${authToken.token}`)
  }

  if (!environment.production && env) {
    httpHeaders = httpHeaders.set('environment', env)
  }
  return httpHeaders
}

const modifyRequest = (req: HttpRequest<any>) => {
  return req.clone({
    url: req.url.includes('assets') ? req.url : `${environment.policyRetrieveApiURL}/${req.url}`,
    headers: addExtraHeaders(req.headers)
  })
}

const handleError = (error: HttpErrorResponse) => {
  // const env = env$()
  if (!error.url.includes('logger')) {
    logger$.set(error.message)
  }
  //do not logout in localhost with mockdata as logger returns 403 with mockdata so adding env check
  // if (env && (error.status === 401 || error.status === 403)) logout()
  if (error.status === 401 || error.status === 403) logout()
  if (error.url.includes('policyManagement')) throw 'policyManagement'
  else if (error.url.includes('policyRetrieve')) throw 'policyRetrieve'

  throw 'unknownError'
}

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  showLoader$.set(true)
  const request = modifyRequest(req)
  return next(request).pipe(
    catchError(handleError),
    finalize(() => showLoader$.set(false))
  )
}
