import { CanActivateFn } from '@angular/router'
import { inject, Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { authToken$ } from '../store'
import { logout } from '../util/app.util'

@Injectable({ providedIn: 'root' })
class PolicyViewRouteGuard {
  constructor(private cookieService: CookieService) {}

  hasAuthToken() {
    const oktaToken = this.cookieService.get('sf-cauth1')
    if (oktaToken) authToken$.set({ type: 'okta', token: oktaToken })
    else {
      logout()
      return false
    }
    return true
  }
}

export const policyViewGuard: CanActivateFn = (route, state) => {
  return inject(PolicyViewRouteGuard).hasAuthToken()
}
