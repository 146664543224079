@if (showHeaderFooterLinks) {
  <header data-type="1x-header-auth" data-limited="false" data-openmyaccounts="false"></header>
}
<main class="-oneX-container">
  <router-outlet></router-outlet>
</main>
@if (showHeaderFooterLinks) {
  <footer data-limited="true" data-type="1x-footer"></footer>
}

<app-loader></app-loader>
<app-session-timeout></app-session-timeout>
